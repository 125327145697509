// src/App.js

import React, { useState } from "react";
import RiskAssessmentForm from "./RiskAssessmentForm";
import RiskList from "./RiskList";

const App = () => {
  const [risks, setRisks] = useState([]);

  const handleAssessment = (risks) => {
    setRisks(risks);
  };

  return (
    <div className="App container">
      <h1>LKSG AI Checker</h1>
      <RiskAssessmentForm onAssess={handleAssessment} />
      <RiskList risks={risks} />
    </div>
  );
};

export default App;

// src/CountrySelect.js

import React from "react";
import Select from "react-select";
import countries from "i18n-iso-countries";
import "/node_modules/flag-icons/css/flag-icons.min.css";

countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const getCountryOptions = () => {
  const countryCodes = countries.getAlpha2Codes();
  return Object.keys(countryCodes).map((code) => ({
    value: countries.getName(code, "de"),
    label: (
      <div>
        <span className={`fi fi-${code.toLowerCase()}`} />{" "}
        {countries.getName(code, "de")}
      </div>
    ),
  }));
};

const CountrySelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={getCountryOptions()}
      isSearchable={true}
    />
  );
};

export default CountrySelect;

// src/RiskAssessmentForm.js

import React, { useState } from "react";
import CountrySelect from "./CountrySelect";
import "./LoadingSpinner.css"; // Import the spinner CSS

const RiskAssessmentForm = ({ onAssess }) => {
  const [country, setCountry] = useState("");
  const [industry, setIndustry] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New state variable
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/risk-assessment/assessment?country=${country?.value}&industry=${industry}`
      );
      const risks = await response.json();
      onAssess(risks);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Land:
        <CountrySelect
          id="country"
          value={country}
          onChange={(selected) => {
            setCountry(selected);
          }}
        />
      </label>
      <label>
        Branche:
        <input
          type="text"
          value={industry}
          onChange={(e) => setIndustry(e.target.value)}
        />
      </label>
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <button type="submit">Check</button>
      )}
    </form>
  );
};

export default RiskAssessmentForm;

// src/RiskList.js

import React, { useState } from "react";

const RiskItem = ({ risk, riskLevel }) => {
  const [expanded, setExpanded] = useState(false);

  const getColor = () => {
    if (riskLevel === 0) return "green";
    if (riskLevel === 1) return "light-green";
    if (riskLevel === 4) return "red";
    if (riskLevel === 3) return "orange";
    return "yellow";
  };

  return (
    <div className="risk-item">
      <div onClick={() => setExpanded(!expanded)} className="risk-item-header">
        <span className="risk-circle" style={{ color: getColor() }}>
          ●
        </span>{" "}
        {risk.risk}
      </div>
      {expanded && (
        <div>
          <div>
            <h3>Gesetzliche Anforderungen</h3>
            <p className="law">{risk.law}:</p>
            <p className="answer">{risk.answer}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const RiskList = ({ risks }) => (
  <div>
    {risks.map((riskObj, idx) => (
      <RiskItem key={idx} risk={riskObj} riskLevel={riskObj.riskLevel} />
    ))}
  </div>
);

export default RiskList;
